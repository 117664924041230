<template>
    <div>
        <h3>{{ $t("add_new_company") }}</h3>
        <p>
            {{ $t("we_need_some_basic_data_from_you") }}
        </p>
        <validation-observer ref="companyRegisterValidation" name="RegisterCompany">
            <b-form @submit="onSubmit" @reset="onReset">
                <b-card>
                    <b-row m-0 p-0>
                        <b-col cols="6">
                            <b-form-group id="domicile-country-group" :label="$t('domicile_country') + ':'"
                                          label-for="domicile-country">
                                <validation-provider #default="{ errors }" name="Country" rules="required">
                                    <b-form-select id="domicile-country" v-model="country_id" :options="countries"
                                                   :state="errors.length > 0 ? false : null" required
                                                   :disabled="disableInputs"></b-form-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                        </b-col>
                        <b-col cols="6">
                            <img src="/loader.svg" v-if="showLoader"/>
                            <!-- {{ company_zefix_data ?  JSON.stringify(company_zefix_data) : ''  }} -->
                        </b-col>
                    </b-row>
                </b-card>
                <b-card>
                    <b-row m-0 p-0>
                        <b-col cols="6">
                            <b-form-group id="role" :label="$t('role') + ':'" label-for="role">
                                <validation-provider #default="{ errors }" name="role" rules="required">

                                    <b-form-select id="role" v-model="role" required :options="[

                                    { text: $t('debtor'), value: 'debtor' },
                                    { text: $t('seller'), value: 'seller' },
                                ]"></b-form-select>
                                    <small class="text-danger">{{ errors[0] }}</small>

                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row m-0 p-0>
                        <b-col cols="6">
                            <b-form-group id="company-name-field-group" :label="$t('company_name') + ':'"
                                          label-for="company-name-field">
                                <validation-provider #default="{ errors }" name="Company Name" rules="required">
                                    <b-form-input id="company-name-field" v-model="company_name" required
                                                  :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group id="company-address-group" :label="$t('company-address') + ':'"
                                          label-for="company-address">
                                <validation-provider #default="{ errors }" name="Company Address" rules="required">
                                    <b-form-input id="company-address" v-model="company_address"
                                                  :state="errors.length > 0 ? false : null" required
                                                  :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="2">
                            <b-form-group id="company-address-nr-group" :label="$t('company-address-nr') + ':'"
                                          label-for="company-address-nr">
                                <validation-provider #default="{ errors }" name="Company Address Nr" rules="required">
                                    <b-form-input id="company-address-nr" v-model="company_address_nr" required
                                                  :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row m-0 p-0>
                        <b-col cols="6">
                            <b-form-group id="company-uid-group" :label="$t('company_uid ') + '(CHE-397.517.219 > CHE397517219):'
                                " label-for="company-uid">
                                <validation-provider #default="{ errors }" name="Company UID" rules="required">
                                    <b-form-input id="company-uid" v-model="company_uid" required
                                                  :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="2">
                            <b-form-group id="company-zip-group" :label="$t('zip') + ':'" label-for="company-zip">
                                <validation-provider #default="{ errors }" name="Company Zip" rules="required">
                                    <b-form-input id="company-zip" v-model="company_zip" required
                                                  :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group id="company-city-group" :label="$t('city') + ':'" label-for="company-city">
                                <validation-provider #default="{ errors }" name="Company City" rules="required">
                                    <b-form-input id="company-city" v-model="company_city" required
                                                  :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row m-0 p-0>
                        <b-col cols="6">
                            <b-form-group id="company-legal-form-group" :label="$t('legal_form') + ':'"
                                          label-for="company-legal-form">
                                <validation-provider #default="{ errors }" name="Company Legal Form" rules="required">
                                    <b-form-select id="company-legal-form" v-model="company_legal_form" :options="[
                                        { value: 0, text: $t('sole_proprietorship') },
                                        { value: 1, text: $t('general_partnership') },
                                        { value: 2, text: $t('limited_partnership') },
                                        { value: 3, text: $t('corporation') },
                                        { value: 4, text: $t('limited_liability_company') },
                                        { value: 5, text: $t('cooperative') },
                                        { value: 6, text: $t('cantonal_public_company')},
                                        { value: 7, text: $t('association')}
                                    ]" required :state="errors.length > 0 ? false : null"
                                                   :disabled="disableInputs"></b-form-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- <b-col cols="6">
                <b-form-group
                  id="company-website-group"
                  :label="$t('website') + ':'"
                  label-for="company-website"
                >
                  <b-form-input
                    id="company-website"
                    v-model="company_website"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col> -->
                    </b-row>
                </b-card>



                <!--<h5>{{ $t("financial_data") }}</h5>-->
                <!--<b-card>-->
                    <!--<b-row m-0 p-0>-->
                        <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
                            <!--<b-form-group id="company-established-at-group" :label="$t('established_since') + ':'"-->
                                          <!--label-for="company-established-at-field">-->
                                <!--<validation-provider #default="{ errors }" name="Established since" rules="required">-->
                                    <!--<b-form-datepicker no-flip id="company-established-at-field"-->
                                                       <!--v-model="company_established_at" :state="errors.length > 0 ? false : null"-->
                                                       <!--:disabled="disableInputs" required></b-form-datepicker>-->
                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                <!--</validation-provider>-->
                            <!--</b-form-group>-->
                        <!--</div>-->
                        <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
                            <!--<b-form-group id="company-number-of-employees-group" :label="$t('number_of_employees') + ':'"-->
                                          <!--label-for="company-number-of-employees">-->
                                <!--<validation-provider #default="{ errors }" name="Number of employees" rules="required">-->
                                    <!--<b-form-input id="company-number-of-employees" v-model="number_of_employees" required-->
                                                  <!--:state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>-->
                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                <!--</validation-provider>-->
                            <!--</b-form-group>-->
                        <!--</div>-->
                    <!--</b-row>-->
                    <!--<b-row m-0 p-0>-->
                        <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
                            <!--<b-form-group :label="$t('annual_turnover') + ':'">-->
                                <!--<validation-provider #default="{ errors }" name="Annual turnover" rules="required">-->
                                    <!--<b-input-group>-->
                                        <!--<b-input-group-prepend id="selectedCurrency">-->

                                            <!--<b-form-select v-model="selected_currency" :options="currenciesOptions"-->
                                                           <!--:disabled="disableInputs">-->

                                            <!--</b-form-select>-->
                                        <!--</b-input-group-prepend>-->
                                        <!--<b-form-input v-model="annual_turnover" class="form-control"-->
                                                      <!--:state="errors.length > 0 ? false : null">-->
                                        <!--</b-form-input>-->
                                    <!--</b-input-group>-->
                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                <!--</validation-provider>-->
                            <!--</b-form-group>-->
                        <!--</div>-->
                        <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
                            <!--<b-form-group id="company-intent-to-sell-group"-->
                                          <!--:label="$t('how_much_do_you_intend_to_sell') + '?'" label-for="company-intent-to-sell">-->
                                <!--<validation-provider #default="{ errors }" name="Intend to sell" rules="required">-->
                                    <!--<b-form-select id="company-intent-to-sell" v-model="intent_to_sell"-->
                                                   <!--:options="intent_to_sell_values" required :state="errors.length > 0 ? false : null"-->
                                                   <!--:disabled="disableInputs"></b-form-select>-->
                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                <!--</validation-provider>-->
                            <!--</b-form-group>-->
                        <!--</div>-->
                    <!--</b-row>-->
                    <!--<b-row m-0 p-0>-->
                        <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
                            <!--<b-form-group id="company-number-of-debtors-group" :label="$t('total_number_of_debtors') + ':'"-->
                                          <!--label-for="company-number-of-debtors">-->
                                <!--<validation-provider #default="{ errors }" name="Number of Debtors" rules="required">-->
                                    <!--<b-form-select id="company-number-of-debtors" v-model="number_of_debtors"-->
                                                   <!--:options="number_of_debtors_values" required-->
                                                   <!--:state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-select>-->
                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                <!--</validation-provider>-->
                            <!--</b-form-group>-->
                        <!--</div>-->
                        <!--<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
                            <!--<b-form-group :label="$t('open_debt_enforcement_against_you') + '?'"-->
                                          <!--v-slot="{ ariaDescribedby }">-->
                                <!--<b-form-radio-group id="company-debt-enforcement" v-model="debt_enforcement"-->
                                                    <!--:options="debt_enforcement_values" :aria-describedby="ariaDescribedby"-->
                                                    <!--name="debt-enforcement"></b-form-radio-group>-->
                            <!--</b-form-group>-->
                        <!--</div>-->
                    <!--</b-row>-->
                <!--</b-card>-->




                <!-- <h5>Main Contact Person</h5>
                <b-card>
                    <b-row m-0 p-0>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <b-form-group id="main-contact-gender-group" :label="$t('salutation') + ':'"
                                label-for="main-contact-gender">
                                <b-form-select id="main-contact-gender" v-model="main_contact_gender" :options="[
                                    {
                                        text: $t('select_one'),
                                        value: null,
                                        disabled: true,
                                    },
                                    { text: $t('mr'), value: '0' },
                                    { text: $t('mrs'), value: '1' },
                                ]" required></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <b-form-group id="main-contact-email-group" :label="$t('company_email_address') + ':'"
                                label-for="main-contact-email">
                                <validation-provider #default="{ errors }" name="company_email_address" rules="required|email">
                                    <b-form-input id="main-contact-email" v-model="main_contact_email" required
                                        :state="errors.length > 0 ? false : null"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>
                    </b-row>
                    <b-row m-0 p-0>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <b-form-group id="main-contact-first-name-group" :label="$t('first_name') + ':'"
                                label-for="main-contact-first-name">
                                <validation-provider #default="{ errors }" name="First name" rules="required">
                                    <b-form-input id="main-contact-first-name" v-model="main_contact_first_name" required
                                        :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <b-form-group id="tel-group" :label="$t('mobile') + ':'" label-for="tel">
                                <validation-provider #default="{ errors }" name="tel" rules="required">
                                    <b-input-group>
                                        <b-input-group-prepend id="phoneCountries">
                                            <b-form-select :options="phoneCountriesList" v-model="phoneContries">
                                            </b-form-select>
                                        </b-input-group-prepend>
                                        <b-form-input id="main-contact-tel" v-model="main_contact_tel"
                                            @input="checkPhoneFormat" class="form-control"
                                            :state="errors.length > 0 ? false : null">
                                        </b-form-input>
                                    </b-input-group>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                    <small class="text-danger" v-if="phoneValidation">{{
                                        phoneValidation
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>
                    </b-row>
                    <b-row m-0 p-0>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <b-form-group id="main-contact-last-name-group" :label="$t('last_name') + ':'"
                                label-for="main-contact-last-name">
                                <validation-provider #default="{ errors }" name="Last Name" rules="required">
                                    <b-form-input id="main-contact-last-name" v-model="main_contact_last_name" required
                                        :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <b-form-group id="main-contact-position-group" :label="$t('position_in_the_company') + ':'"
                                label-for="main-contact-position">
                                <validation-provider #default="{ errors }" name="Position in the Company" rules="required">
                                    <b-form-input id="main-contact-position" v-model="main_contact_position" required
                                        :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>
                    </b-row>
                    <b-row m-0 p-0>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <b-form-group id="signing-authority-type-group" :label="$t('salutation') + ':'"
                                label-for="signing-authority-type">
                                <b-form-select id="signing-authority-type" v-model="signing_authority_type" :options="[
                                    {
                                        text: $t('select_one'),
                                        value: null,
                                        disabled: true,
                                    },
                                    { text: $t('no_signatory'), value: '2' },
                                    { text: $t('sole_signatory'), value: '0' },
                                    { text: $t('collectively_by_two'), value: '1' },
                                ]" required></b-form-select>
                            </b-form-group>
                        </div>
                    </b-row>
                </b-card>
                <h5 v-if="signators.length > 0">{{ $t("other_signers") }}</h5>
                <b-card style="background: #fefefe" v-for="(signator, index) in signators" :key="index">
                    <b-row m-0 p-0>
                        <b-col cols="6">
                            <b-form-group :id="index + 'signator-gender-group'" :label="$t('salutation') + ':'"
                                :label-for="index + 'signator-gender'">
                                <b-form-select :id="index + 'signator-gender'" v-model="signator.gender" :options="[
                                    {
                                        text: $t('select_one'),
                                        value: null,
                                        disabled: true,
                                    },
                                    { text: $t('mr'), value: '0' },
                                    { text: $t('mrs'), value: '1' },
                                ]" required></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :id="index + 'signator-email-group'" :label="$t('company_email_address') + ':'"
                                :label-for="index + 'signator-email'">
                                <validation-provider #default="{ errors }" :name="index + ' Email'" rules="required|email">
                                    <b-form-input :id="index + 'signator-email'" v-model="signator.email" required
                                        :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row m-0 p-0>
                        <b-col cols="6">
                            <b-form-group :id="index + 'signator-first-name-group'" :label="$t('first_name') + ':'"
                                :label-for="index + 'signator-first-name'">
                                <validation-provider #default="{ errors }" :name="index + ' First Name'" rules="required">
                                    <b-form-input :id="index + 'signator-first-name'" v-model="signator.first_name" required
                                        :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :id="index + 'signator-tel-group'" :label="$t('mobile_phone') + ':'"
                                :label-for="index + 'signator-tel'">
                                <b-form-input :id="index + 'signator-tel'" v-model="signator.tel"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row m-0 p-0>
                        <b-col cols="6">
                            <b-form-group :id="index + 'signator-last-name-group'" :label="$t('last_name') + ':'"
                                :label-for="index + 'signator-last-name'">
                                <validation-provider #default="{ errors }" :name="index + ' Last Name'" rules="required">
                                    <b-form-input :id="index + 'signator-last-name'" v-model="signator.last_name" required
                                        :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :id="index + 'signator-position-group'"
                                :label="$t('position_in_the_company') + ':'" :label-for="index + 'signator-position'">
                                <validation-provider #default="{ errors }" :name="index + ' Position'" rules="required">
                                    <b-form-input :id="index + 'signator-position'" v-model="signator.position" required
                                        :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row m-0 p-0>
                        <b-col cols="6">
                            <b-form-group :id="index + 'signator-signing-authority-type-group'"
                                :label="$t('signing_authority_type') + ':'"
                                :label-for="index + 'signator-signing-authority-type'">
                                <b-form-select :id="index + 'signator-signing-authority-type'"
                                    v-model="signator.signing_authority_type" :options="signing_authority_type_values"
                                    required></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="text-right pt-2">
                            <b-button variant="outline-danger" @click="deleteSignator(index)">
                                <feather-icon icon="DeleteIcon"></feather-icon>
                                {{ $t("delete") }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
                <b-row m-0 p-0 v-if="signators.length < 5">
                    <b-col cols="12" class="text-right">
                        <b-button variant="outline-primary" @click="addNewSigner()">
                            <feather-icon icon="PlusIcon"></feather-icon>
                            {{ $t("add_signatory") }}
                        </b-button>
                    </b-col>
                </b-row> -->
                <!--<h5>{{ $t("fee_policy") }}</h5>-->
                <!--<b-card>-->
                    <!--<b-row m-0 p-0>-->
                        <!--<b-col cols="12">-->
                            <!--&lt;!&ndash; terms-and-conditions &ndash;&gt;-->
                            <!--<b-form-group>-->
                                <!--&lt;!&ndash;<validation-provider&ndash;&gt;-->
                                <!--&lt;!&ndash;#default="{ errors }"&ndash;&gt;-->
                                <!--&lt;!&ndash;name="data_privacy"&ndash;&gt;-->
                                <!--&lt;!&ndash;rules="required"&ndash;&gt;-->
                                <!--&lt;!&ndash;&gt;&ndash;&gt;-->
                                <!--<b-form-checkbox-->
                                    <!--id="data_privacy"-->
                                    <!--v-model="data_privacy"-->
                                    <!--name="data_privacy"-->
                                    <!--:disabled="disableInputs"-->
                                <!--&gt;-->
                                    <!--{{ $t("agree_with_policy_seller") }}-->
                                <!--</b-form-checkbox>-->
                                <!--&lt;!&ndash;<small class="text-danger">{{ errors[0] }}</small>&ndash;&gt;-->
                                <!--&lt;!&ndash;</validation-provider>&ndash;&gt;-->
                                <!--<div style="background: #efefef; padding: 10px" class="mt-1">-->
                                    <!--<p>-->
                                        <!--{{ $t("agree_with_policy_seller_subtitle") }}-->

                                        <!--&lt;!&ndash; Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. &ndash;&gt;-->
                                    <!--</p>-->
                                <!--</div>-->
                            <!--</b-form-group>-->
                        <!--</b-col>-->
                    <!--</b-row>-->
                <!--</b-card>-->
                <!--<h5>{{ $t("legal_notice") }}</h5>-->
                <!--<b-card>-->
                    <!--<b-row m-0 p-0>-->
                        <!--<b-col cols="12">-->
                            <!--&lt;!&ndash; terms-and-conditions &ndash;&gt;-->
                            <!--<b-form-group>-->
                                <!--&lt;!&ndash;<validation-provider #default="{ errors }" name="terms-and-conditions" rules="required">&ndash;&gt;-->
                                <!--<b-form-checkbox id="terms-and-conditions" v-model="terms_and_conditions"-->
                                                 <!--name="terms-and-conditions" :disabled="disableInputs">-->
                                    <!--{{ $t("agree_with_terms_&_conditions_seller_before_link") }}-->
                                    <!--<span class="web">-->
                                            <!--<router-link to="/files/terms_de" target="_blank"-->
                                                         <!--v-if="$store.state.app.language == 'de'">{{-->
                                                    <!--$t("agree_with_terms_&_conditions_seller_link")-->
                                                <!--}}</router-link>-->
                                            <!--<router-link to="/files/terms" target="_blank" v-else>{{-->
                                                <!--$t("agree_with_terms_&_conditions_seller_link")-->
                                            <!--}}</router-link>-->
                                        <!--</span>-->
                                    <!--<span class="mobile">-->
                                            <!--<a v-if="$store.state.app.language == 'de'" href="/files/terms_de.pdf"-->
                                               <!--download="">{{-->
                                                    <!--$t("agree_with_terms_&_conditions_seller_link")-->
                                                <!--}}</a>-->
                                            <!--<a v-else href="/files/terms_eng.pdf" download="">{{-->
                                                <!--$t("agree_with_terms_&_conditions_seller_link")-->
                                            <!--}}</a>-->
                                        <!--</span>-->
                                <!--</b-form-checkbox>-->
                                <!--&lt;!&ndash;<small class="text-danger">{{ errors[0] }}</small>&ndash;&gt;-->
                                <!--&lt;!&ndash;</validation-provider>&ndash;&gt;-->
                                <!--<div style="background: #efefef; padding: 10px" class="mt-1">-->
                                    <!--<p>-->
                                        <!--{{ $t("agree_with_terms_&_conditions_seller_subtitle") }}-->

                                        <!--&lt;!&ndash; Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. &ndash;&gt;-->
                                    <!--</p>-->
                                    <!--<p class="col-md-12">-->
                                        <!--{{ $t("agree_with_terms_&_conditions_seller_1") }}-->

                                        <!--&lt;!&ndash; Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. &ndash;&gt;-->
                                    <!--</p>-->
                                    <!--<p class="col-md-12">-->
                                        <!--{{ $t("agree_with_terms_&_conditions_seller_2") }}-->
                                    <!--</p>-->
                                <!--</div>-->
                            <!--</b-form-group>-->
                        <!--</b-col>-->
                    <!--</b-row>-->
                <!--</b-card>-->
                <b-row m-0 p-0>
                    <b-col cols="12" class="text-right">
                        <b-button variant="primary" @click="saveCompanyInfo()">
                            <feather-icon icon="SaveIcon"></feather-icon>
                            {{ $t("save_company_information") }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
  import {ValidationObserver, ValidationProvider} from "vee-validate";
  import {email, required} from "@validations";
  import {phone} from "phone";

  import {
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormSelect,
    BInputGroup,
    BRow,
  } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BForm,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BInputGroup,
      BFormCheckbox,
      BFormDatepicker,
      BFormRadio,
      BFormRadioGroup,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        currenciesOptions: [],
        role: null,
        phoneCountriesList: [],
        phoneContries: "CH",
        phoneValidation: "",
        showLoader: false,
        disableInputs: false,
        country_id: 1,
        countries_code: [],
        countries: [],
        company_id: this.$route.params.id,
        company_name: null,
        companies: [],
        company_zefix_data: null,
        company_address: null,
        company_address_nr: null,
        company_uid: null,
        company_zip: null,
        company_city: null,
        company_legal_form: null,
        company_website: null,
        legal_forms: [
          "Aktiengesellschaft",
          "Gesellschaft mit beschränkter Haftung",
          "Einzelunternehmen",
        ],
        company_established_at: null,
        number_of_employees: null,
        annual_turnover: null,
        selected_currency: 1,
        intent_to_sell: "1% - 10%",
        number_of_debtors: "1-10",
        debt_enforcement: "Yes",
        intent_to_sell_values: [
          "1% - 10%",
          "11% - 20%",
          "21% - 30%",
          "31% - 50%",
          "51% - 70%",
          "71% - 80%",
          "81% - 90%",
          "91% - 100%",
        ],
        number_of_debtors_values: [
          "1-10",
          "11-20",
          "21-50",
          "51-100",
          "101-200",
          "201-500",
          "500+",
        ],
        debt_enforcement_values: ["Yes", "No"],
        main_contact_gender: "Mr",
        main_contact_email: null,
        main_contact_first_name: null,
        main_contact_last_name: null,
        main_contact_tel: null,
        main_contact_position: null,
        signing_authority_type: "Collectively by two",
        signing_authority_type_values: [
          this.$t("no_signatory"),
          this.$t("sole_signatory"),
          this.$t("collectively_by_two"),
          this.$t("only_contact_person"),
        ],
        signators: [
          //   {
          //     gender: "Mr",
          //     first_name: null,
          //     email: null,
          //     last_name: null,
          //     tel: null,
          //     position: null,
          //     signing_authority_type: "Collectively by two",
          //   },
        ],
        terms_and_conditions: 0,
        data_privacy: 0,
      };
    },
    name: "CompanyForm",
    created() {

      this.getCurrencies();

      this.$http.post("/countries").then((res) => {
        res.data.value.map((item) => {
          this.countries_code.push(item);
          this.countries.push({
            value: item.id,
            text: item.name,
          });
        });
      });

      this.$http.post("/countries").then((res) => {
        res.data.value.map((item) => {
          this.phoneCountriesList.push({
            value: item.iso_code,
            text: `${item.iso_code} (${item.phone_code})`,
          });

          this.countries.push({
            value: item.id,
            text: item.name,
          });
        });
      });

      if (this.$route.params.id) {
        this.getCompanyData();
      }

    },
    methods: {
      getCurrencies() {
        this.$http.post("/currency").then((response) => {
          if (response.data.status == 200) {

            response.data.value.map((item) => {
              this.currenciesOptions.push({
                value: item.id,
                text: item.name,
              })
            })

          }
        });
      },
      checkPhoneFormat() {
        let validation = phone(this.main_contact_tel, {
          country: this.phoneContries,
          validateMobilePrefix: false
        });

        if (validation && validation.isValid == false) {
          this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
        } else {
          this.phoneValidation = "";
        }
      },
      getCompanyData() {
        this.$http.post("/countries").then((res) => {
          res.data.value.map((item) => {
            this.countries.push({
              value: item.id,
              text: item.name,
            });
          });
        });
      },
      saveCompanyInfo() {
        this.$refs.companyRegisterValidation.validate().then((success) => {
          if (success) {
            this.disableInputs = true;
            const formSubmissionData = {
              role: this.role,
              company_id: this.company_id,
              company_name: this.company_name,
              company_address: this.company_address,
              company_address_nr: this.company_address_nr,
              company_uid: this.company_uid,
              company_zip: this.company_zip,
              company_city: this.company_city,
              company_country_id: this.country_id,
              //   company_website: this.company_website,
              // main_contact_person_gender: this.main_contact_gender,
              // main_contact_first_name: this.main_contact_first_name,
              // main_contact_last_name: this.main_contact_last_name,
              // main_contact_position: this.main_contact_position,
              // main_contact_tel: this.getPhone(
              //     this.main_contact_iso_code,
              //     this.main_contact_tel
              // ),
              // signing_authority_type: this.signing_authority_type,
              established_at: this.established_at,
              number_of_employees: this.number_of_employees,
              annual_turnover: this.annual_turnover,
              intent_to_sell: this.intent_to_sell,
              number_of_debtors: this.number_of_debtors,
              debt_enforcement: this.debt_enforcement,
              signers: this.signators.length,
              terms_and_conditions: this.terms_and_conditions,
              data_privacy: this.data_privacy,
              placeholder: 1,
              language: this.$store.state.app.language,
              capital_currency_id: this.selected_currency
            };
            let data = {};
            this.signators.map((item, i) => {
              const nr = i + 1;
              const signator = {
                ["signator_" + nr + "_gender"]: item.gender,
                ["signator_" + nr + "_first_name"]: item.first_name,
                ["signator_" + nr + "_last_name"]: item.last_name,
                ["signator_" + nr + "_email"]: item.email,
                ["signator_" + nr + "_tel"]: item.tel,
                ["signator_" + nr + "_position"]: item.position,
                ["signator_" + nr + "_signing_authority_type"]:
                item.signing_authority_type,
              };
              data = {...formSubmissionData, ...signator};

            });
            data = formSubmissionData;

            this.$http.post("/company/admin/store", data).then((res) => {
              if (res) {
                if (res.data.status == 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t("company_registered_successfully"),
                      icon: "SuccessIcon",
                      variant: "success",
                    },
                  });
                  this.$router.push("/companies");
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: res.data.message,
                      icon: "ErrorIcon",
                      variant: "danger",
                    },
                  });
                }

              }


            });
          } else {
            console.log('successs')
          }
          this.disableInputs = false;
        });
      },
      deleteSignator(index) {
        this.signators.splice(index, 1);
      },
      searchForCompaniesName() {
        if (this.company_name.length > 2) {
          this.$http
              .post("/get-companies-name-from-zefix", {search: this.company_name})
              .then((res) => {
                this.companies = res.data.value;
              });
        }
      },
      getPhone(iso, tel) {
        if (this.countries_code && this.countries_code.length) {
          this.countries_code.map((i, item) => {
            if (i.iso_code == iso) {
              tel = tel.replace(i.phone_code, "");
            }
          });
        }
        return tel;
      },
      getCompanyDataFromZefix() {
        this.showLoader = true;
        if (typeof this.companies[this.company_name] != "undefined") {
          this.$http
              .post("/get-company-data-from-zefix", {
                search: this.companies[this.company_name],
              })
              .then((res) => {
                this.company_zefix_data = res.data.value;
                this.company_uid = this.company_zefix_data.uid;
                this.company_address = this.company_zefix_data.address.street;
                this.company_address_nr =
                    this.company_zefix_data.address.houseNumber;
                this.company_city = this.company_zefix_data.address.city;
                this.company_zip = this.company_zefix_data.address.swissZipCode;
                this.company_legal_form = this.company_zefix_data.legalForm.name.de;
                this.company_established_at = this.company_zefix_data.sogcDate;
                this.showLoader = false;
              });
        }
      },
      addNewSigner() {
        if (this.signators.length < 5) {
          this.signators.push({
            gender: "Mr",
            first_name: null,
            email: null,
            last_name: null,
            tel: null,
            position: null,
            signing_authority_type: null,
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("you_can_add_only_up_to_5_signatory"),
              icon: "ErrorIcon",
              variant: "error",
            },
          });
        }
      },
      onSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.form));
      },
      onReset(event) {
        event.preventDefault();
        this.country_id = 0;
        this.countries = [];
      },
    },
  };
</script>

<style scoped></style>
  