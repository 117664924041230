var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("add_new_company")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("we_need_some_basic_data_from_you"))+" ")]),_c('validation-observer',{ref:"companyRegisterValidation",attrs:{"name":"RegisterCompany"}},[_c('b-form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"domicile-country-group","label":_vm.$t('domicile_country') + ':',"label-for":"domicile-country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"domicile-country","options":_vm.countries,"state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.country_id),callback:function ($$v) {_vm.country_id=$$v},expression:"country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[(_vm.showLoader)?_c('img',{attrs:{"src":"/loader.svg"}}):_vm._e()])],1)],1),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"role","label":_vm.$t('role') + ':',"label-for":"role"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"role","required":"","options":[

                                { text: _vm.$t('debtor'), value: 'debtor' },
                                { text: _vm.$t('seller'), value: 'seller' } ]},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-name-field-group","label":_vm.$t('company_name') + ':',"label-for":"company-name-field"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-name-field","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"id":"company-address-group","label":_vm.$t('company-address') + ':',"label-for":"company-address"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address","state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.company_address),callback:function ($$v) {_vm.company_address=$$v},expression:"company_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"id":"company-address-nr-group","label":_vm.$t('company-address-nr') + ':',"label-for":"company-address-nr"}},[_c('validation-provider',{attrs:{"name":"Company Address Nr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address-nr","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_address_nr),callback:function ($$v) {_vm.company_address_nr=$$v},expression:"company_address_nr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-uid-group","label":_vm.$t('company_uid ') + '(CHE-397.517.219 > CHE397517219):',"label-for":"company-uid"}},[_c('validation-provider',{attrs:{"name":"Company UID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-uid","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_uid),callback:function ($$v) {_vm.company_uid=$$v},expression:"company_uid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"id":"company-zip-group","label":_vm.$t('zip') + ':',"label-for":"company-zip"}},[_c('validation-provider',{attrs:{"name":"Company Zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-zip","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_zip),callback:function ($$v) {_vm.company_zip=$$v},expression:"company_zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"id":"company-city-group","label":_vm.$t('city') + ':',"label-for":"company-city"}},[_c('validation-provider',{attrs:{"name":"Company City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-city","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_city),callback:function ($$v) {_vm.company_city=$$v},expression:"company_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"company-legal-form-group","label":_vm.$t('legal_form') + ':',"label-for":"company-legal-form"}},[_c('validation-provider',{attrs:{"name":"Company Legal Form","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"company-legal-form","options":[
                                    { value: 0, text: _vm.$t('sole_proprietorship') },
                                    { value: 1, text: _vm.$t('general_partnership') },
                                    { value: 2, text: _vm.$t('limited_partnership') },
                                    { value: 3, text: _vm.$t('corporation') },
                                    { value: 4, text: _vm.$t('limited_liability_company') },
                                    { value: 5, text: _vm.$t('cooperative') },
                                    { value: 6, text: _vm.$t('cantonal_public_company')},
                                    { value: 7, text: _vm.$t('association')}
                                ],"required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_legal_form),callback:function ($$v) {_vm.company_legal_form=$$v},expression:"company_legal_form"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveCompanyInfo()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t("save_company_information"))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }